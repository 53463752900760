/* eslint-disable indent */
import {
	fetchPostDetail,
	fetchPostInPage,
	fetchPostReplyList,
	postReplyCreate,
	postReplyEdit,
} from '@/api/mentreply/mentreply.api'
import { CreatePayload, EditPayload } from '@/api/mentreply/type'
import { defineStore } from 'pinia'
import { FeedDetail, PostType } from './type'
import dayjs from '@/utils/dayjs'
import firebase from 'firebase/app'
import { useShopStore } from '../shop/shop.pinia'
import { SHIPMENT_STATUS } from '@/api/live/live.type'

export const useMentreply = defineStore('mentreply', {
	state: () => ({
		postTypeSelect: PostType.BY_POST,
		customerMessage: [{ message: '' }],
		hashTag: [],
		isLikeComment: false,
		isNotDuplicateReplyUser: false,
		isHideComment: false,
		isDisplayCommentMessageDirect: false,
		replyMessage: '',
		chatReplyMessage: '',
		imageDirect: null as any,
		imageReplies: [],
		// mentlist
		mentReplyPage: {
			mentList: [],
			limit: 10,
			offset: 1,
			pages: 0,
		},
		postInPage: {
			list: [],
			limit: 10,
			offset: 1,
			pages: 0,
			afterToken: null,
		},
		selectedPost: [] as FeedDetail[],
	}),
	getters: {
		displayReplyPreivewImage(state) {
			const fileList: any = []
			for (const file of state.imageReplies) {
				if ((file as any) instanceof File) {
					fileList.push(URL.createObjectURL(file))
				} else {
					fileList.push(file)
				}
			}
			return fileList
		},
		displayChatReplyPreviewImage(state): string {
			if (state.imageDirect instanceof File) {
				return URL.createObjectURL(state.imageDirect)
			}
			return state.imageDirect
		},
	},
	actions: {
		addMoreCustomerMessage(message = '') {
			this.customerMessage.push({ message })
		},
		removeCustomerMessage(index: number) {
			this.customerMessage = this.customerMessage.filter(
				(_, i) => i !== index
			)
		},
		async actionGetMentReplyList(pageId: string) {
			const params = {
				pageId,
				limit: this.mentReplyPage.limit,
				offset: this.mentReplyPage.offset,
			}

			const { data: response } = await fetchPostReplyList(params)

			this.mentReplyPage.mentList = response.data
			this.mentReplyPage.pages = response.pagination.pages
		},
		async actionGetPostInPage(pageId: string) {
			const params = {
				pageId,
				limit: this.postInPage.limit,
				offset: this.postInPage.offset,
				afterToken: this.postInPage.afterToken,
			}
			const { data: response } = await fetchPostInPage(params)

			this.postInPage.list = [
				...this.postInPage.list,
				...response.data,
			] as any

			this.postInPage.afterToken = response.afterToken
			// this.postInPage.pages
		},
		async actionCreateMentReply({ pageId }: any) {
			const createPayload = {
				postIds: this.selectedPost.map((post) => post.postId),
				type: this.postTypeSelect,
				name:
					this.postTypeSelect === PostType.GLOBAL
						? 'ทั้งหมด'
						: this.selectedPost
								.map((post) => post.message)
								.join(', '),
				pageId,
				messageReply: this.replyMessage,
				messageDirect: this.chatReplyMessage,
				isLikeComment: this.isLikeComment,
				isDisplayCommentMessageDirect: this.isDisplayCommentMessageDirect,
				isNotDuplicateReplyUser: this.isNotDuplicateReplyUser,
				isHideComment: this.isHideComment,
				detectByWording: this.customerMessage
					.map(({ message }) => message)
					.filter((message) => message),
				detectByTag: this.hashTag,
				imageReplies: [],
			} as CreatePayload

			if (this.imageReplies.length > 0) {
				const imagePathList = await Promise.all(
					this.imageReplies.map((file) =>
						this.actionUploadReplyImage(file)
					)
				)
				createPayload.imageReplies = imagePathList
			}

			if (this.imageDirect) {
				const imagePath = await this.actionUploadReplyImage(
					this.imageDirect
				)
				createPayload.imageDirect = imagePath
			}
			await postReplyCreate(createPayload)
		},
		async actionEditMentReply({ postReplyId }: any) {
			const editPayload = {
				postIds: this.selectedPost.map((post) => post.postId),
				type: this.postTypeSelect,
				name:
					this.postTypeSelect === PostType.GLOBAL
						? 'ทั้งหมด'
						: this.selectedPost
								.map((post) => post.message)
								.join(', '),
				postReplyId,
				messageReply: this.replyMessage,
				messageDirect: this.chatReplyMessage,
				isDisplayCommentMessageDirect: this.isDisplayCommentMessageDirect,
				isLikeComment: this.isLikeComment,
				isNotDuplicateReplyUser: this.isNotDuplicateReplyUser,
				isHideComment: this.isHideComment,
				detectByWording: this.customerMessage
					.map(({ message }) => message)
					.filter((message) => message),
				detectByTag: this.hashTag,
				imageReplies: [],
			} as Omit<EditPayload, 'pageId'>

			if (this.imageReplies.length > 0) {
				const imagePathList = await Promise.all(
					this.imageReplies.map((file) =>
						this.actionUploadReplyImage(file)
					)
				)
				editPayload.imageReplies = imagePathList
			}
			if (this.imageDirect) {
				const imagePath = await this.actionUploadReplyImage(
					this.imageDirect
				)
				editPayload.imageDirect = imagePath
			}

			await postReplyEdit(editPayload)
		},
		async actionGetPostDetail(postId: string) {
			const r = await fetchPostDetail(postId)

			this.postTypeSelect = r.type
			this.customerMessage =
				r.detectByWording.length >= 1
					? r.detectByWording.map((message) => ({ message }))
					: [{ message: '' }]
			this.isLikeComment = r.isLikeComment
			this.isNotDuplicateReplyUser = r.isNotDuplicateReplyUser
			this.isHideComment = r.isHideComment
			;(this.replyMessage = r.messageReply),
				(this.imageDirect = r.imageDirect),
				(this.imageReplies = r.imageReplies as never)
			this.chatReplyMessage = r.messageDirect
			this.selectedPost = r.posts
			this.isDisplayCommentMessageDirect = r.isDisplayCommentMessageDirect as boolean
		},
		async actionUploadReplyImage(image: File | string | null | undefined) {
			const shopStore = useShopStore()
			if (!image) {
				return image
			}
			if (!(image instanceof File)) {
				return image
			}
			const time = dayjs().format('YYYY-MM-DD-HH-mm')
			const [fileName, fileExtension] = (image as any).name.split('.')
			const path = `page-comment-reply/${
				shopStore.selectedShopId
			}/${time}/${dayjs().unix()}-${fileName}.${fileExtension}`
			const storage = firebase.storage().ref().child(path)
			return storage.put(image).then(async (snapshot) => {
				return await snapshot.ref.getDownloadURL()
			})
		},
	},
})
