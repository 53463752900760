export type FeedDetail = {
    postId: string
    message: string
    likeTotalCount: number
    list: PostImage[]
    commentTotalCount: number
    shareTotalCount: number
    createdAt: Date|string
}

export enum PostType {
    GLOBAL = 'GLOBAL',
    BY_POST = 'BY_POST'
}

export type PostImage = {
    cover: string
    type: string
}